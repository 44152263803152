import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatOpened: false,
  },
  reducers: {
    setChatOpened: (state, action) => {
      state.chatOpened = action.payload;
    },
  },
});

export const { setChatOpened } = chatSlice.actions;
export default chatSlice.reducer;
