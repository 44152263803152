import React, { useEffect } from "react";

const MainInputComponent = ({
  type,
  name,
  value,
  onChange,
  error,
  placeholder,
}) => {
  const inputType = type === "number" ? "tel" : type;

  useEffect(() => {
    if (type === "number" && !value.startsWith("+1")) {
      onChange({ target: { name, value: "+1" } });
    }
  }, [type, value, onChange, name]);

  const handleChange = (e) => {
    if (type === "number") {
      if (!e.target.value.startsWith("+1")) {
        e.target.value = "+1" + e.target.value.replace(/^\+?1?/, "");
      }
    }
    onChange(e);
  };

  return (
    <div className="flex flex-col w-full">
      <input
        type={inputType}
        name={name}
        value={value}
        onChange={handleChange}
        className="bg-[#F2F2F2] p-3 rounded-full mb-1 border-2 border-[#818181] outline-none mt-1 w-full text-sm"
        placeholder={placeholder}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      />
      {error && (
        <p className="text-red-500 text-xs mt-1 text-left ml-5">{error}</p>
      )}
    </div>
  );
};

export default MainInputComponent;
