import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IoCameraOutline } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import { IoIosSend } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import SingleChat from "./SingleChat";
import ChatPerson from "./ChatPerson";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import calendaricon from "../../assets/CalendarDots.svg";
import InterviewScheduleModal from "./interview/InterviewScheduleModal";

const ChatComponent = ({
  chatData,
  OpenChat,
  blockOrReportUser,
  deleteChats,
}) => {
  console.log(chatData, "i am chat data");
  const [conversationData, setConversationData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendingMsg, setSendingMsg] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);
  const photoInputRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const selectedLanguage = useSelector((state) => state.language.language);
  const [isInterviewScheduleModalVisible, setIsInterviewScheduleModalVisible] =
    useState(false);

  const openInterviewScheduleModal = () =>
    setIsInterviewScheduleModalVisible(true);
  const closeInterviewScheduleModal = () =>
    setIsInterviewScheduleModalVisible(false);
  const options = translations[selectedLanguage].actions;
  const fetchConversation = async () => {
    try {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/conversation?limit=100&channel_id=${channelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConversationData(response.data.content.conversation);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // Optional: 'auto' for instant scrolling
    });
  }, []);
  useEffect(() => {
    const notichannelId = localStorage.getItem("notichannelId");
    if (notichannelId) {
      localStorage.removeItem("notichannelId");
    }

    fetchConversation();
    const intervalId = setInterval(fetchConversation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //   endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [conversationData]);

  const handleSendMessage = async () => {
    try {
      setSendingMsg(true);
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("token");
      const channelId = localStorage.getItem("channelId");

      const formData = new FormData();
      formData.append("message", message);
      formData.append("channel_id", channelId);

      selectedFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/send-message`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage("");
      setSelectedFiles([]);
      fetchConversation();
      setSendingMsg(false);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file) => {
    const fileType = file.type.split("/")[1];
    if (file.type.startsWith("image/")) {
      return (
        <div className="relative group">
          <img
            src={URL.createObjectURL(file)}
            alt="Preview"
            className="object-cover rounded-lg h-full transition-transform duration-300 transform group-hover:scale-105"
            style={{ maxWidth: "150px", maxHeight: "150px" }}
          />
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300 rounded-lg"></div>
        </div>
      );
    } else if (fileType === "pdf") {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FilePdfOutlined className="text-orange-400 mr-2" />
          {file.name}
        </div>
      );
    } else if (
      fileType === "msword" ||
      fileType === "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <FileWordOutlined className="text-blue-500 mr-2" />
          {file.name}
        </div>
      );
    } else {
      return (
        <div className="flex items-center bg-white bg-opacity-30 p-2 rounded-md">
          <ImAttachment className="mr-2" />
          {file.name}
        </div>
      );
    }
  };

  return (
    <div className="w-full flex flex-col h-screen heightchat  ">
      <div>
        <hr />
        <ChatPerson
          chatData={chatData}
          OpenChat={OpenChat}
          options={options}
          blockOrReportUser={blockOrReportUser}
          deleteChats={deleteChats}
        />
      </div>
      <SingleChat conversationData={conversationData} />
      {/* <div ref={endOfMessagesRef} /> */}
      <div className="w-auto flex flex-col mt-auto">
        <div className="w-full flex flex-wrap justify-center ">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative m-2">
              {renderFilePreview(file)}
              <AiFillCloseCircle
                className="absolute top-0 right-0 text-2xl cursor-pointer text-orange-400"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
        <div className="w-full fixed"></div>
        <div className="  bottom-10  md:w-full w-full bg-white border-t-2 pt-8 justify-center items-center space-x-5 flex lg:mx-0 mx-0 md:mx-0 z-50 mb-2 pb-5  ">
          <input
            type="file"
            ref={fileInputRef}
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <ImAttachment
            className="text-xl cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          />
          <div className="w-9/12 h-10 rounded-full bg-gray-100 ml-auto flex">
            <input
              type="text"
              placeholder="type here."
              className="p-2 w-10/12 bg-transparent outline-none "
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <div
              className="bg-[#E7831D] p-2 rounded-full ml-auto hover:scale-95 hover:bg-orange-400 cursor-pointer"
              onClick={handleSendMessage}
            >
              {sendingMsg ? (
                <div class="flex flex-row justify-center items-center text-center gap-2 mt-2">
                  <div class="w-2 h-2 rounded-full bg-gray-200 animate-bounce [animation-delay:.7s]"></div>
                  <div class="w-2 h-2 rounded-full bg-gray-200 animate-bounce [animation-delay:.3s]"></div>
                </div>
              ) : (
                <IoIosSend className="text-white text-2xl" />
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <img
              src={calendaricon}
              alt="calendaricon"
              className="h-7 cursor-pointer hover:scale-105"
              onClick={openInterviewScheduleModal}
            />
          </div>
        </div>
      </div>
      <InterviewScheduleModal
        isVisible={isInterviewScheduleModalVisible}
        onClose={closeInterviewScheduleModal}
      />
    </div>
  );
};

export default ChatComponent;
