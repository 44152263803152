import React, { Fragment, useState, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import bblogo from "../../assets/276pxlogo.svg";
import crossdialog from "../../assets/crossdialog.svg";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  logo,
  navigate,
  navigation,
  handleNavigationClick,
  activeNotificationCount,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  console.log(navigation, "navigation");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredNavigation = isMobile
    ? navigation.filter(
        (item) =>
          item.name === "Profile" ||
          item.name === "Mi perfil" ||
          item.name === "Settings" ||
          item.name === "Configuración" ||
          item.name === "Subscription" ||
          item.name === "Suscripción" ||
          item.name === "Log In" ||
          item.name === "Iniciar sesión" ||
          item.name === "Log Out" ||
          item.name === "Cerrar sesión"
      )
    : navigation;

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative " onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900/80 mainindex" />
        </Transition.Child>

        <div className="fixed inset-0 flex  mainindex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 ">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute  top-5 right-2 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <img
                      src={crossdialog}
                      alt="crossicon"
                      className="h-8 w-8 "
                    />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-14">
                <nav className="flex flex-1 flex-col mt-5">
                  <img
                    src={bblogo}
                    alt="logo"
                    className="w-[146px] mt-5 cursor-pointer"
                    onClick={() => navigate("/")}
                  />
                  <ul role="list" className="flex flex-1 flex-col gap-y-5">
                    <li>
                      <ul
                        role="list"
                        className="-mx-2 space-y-2 font-bold mt-10"
                      >
                        {filteredNavigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              onClick={() => handleNavigationClick(item.name)}
                              className={classNames(
                                item.current
                                  ? "text-[#E7831D] relative"
                                  : item.name === "Login"
                                  ? "text-[#E7831D] font-bold hover:text-[#E7831D]"
                                  : "text-[#404041] hover:text-[#E7831D]",
                                "group flex gap-x-3 rounded-md p-2 text-md leading-7 text-[15px] font-light"
                              )}
                            >
                              {(item.name === "Notifications" ||
                                item.name === "Notificaciones") &&
                                activeNotificationCount > 0 && (
                                  <div className="flex justify-center items-center absolute">
                                    <span className="bg-[#E7831D] w-5 h-5 rounded-full animate-spin absolute filter z-40"></span>
                                    <span className="rounded-full absolute text-white z-50 flex justify-center items-center text-sm">
                                      {activeNotificationCount}
                                    </span>
                                  </div>
                                )}

                              <SvgIcon
                                src={item.current ? item.iconActive : item.icon}
                                className="h-6 w-6 group-hover:hidden"
                                aria-hidden="true"
                              />
                              <SvgIcon
                                src={item.iconActive}
                                className="h-6 w-6  hidden group-hover:block"
                                aria-hidden="true"
                              />
                              <span
                                className={
                                  item.current
                                    ? "font-bold text-md"
                                    : " text-md"
                                }
                              >
                                {item.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </nav>
                <p className="relative  bg-white   flex text-left items-center  left-0 bottom-2 w-58 justify-center   z-50 text-md text-gray-600 text-[.6rem]">
                  <a href="https://www.bocaenboca.com" target="_blank">
                    {" "}
                    <span className=" mr-1">Boca En Boca</span>
                    is a trademark of The Peddler Group, Inc. Copyright 2022.
                  </a>
                </p>

                <p className="relative  bg-white   flex flex-wrap text-left items-left   left-0 bottom-2 w-58 justify-left   z-50 text-md text-gray-600 text-[.6rem]">
                  <Link to="/set/policy/terms_and_conditions">
                    Terms & Conditions{" "}
                  </Link>
                  <Link to="/set/policy/privacy_policy" className="ml-1">
                    {" "}
                    Privacy Policy
                  </Link>
                  <Link to="/set/policy/cancellation_&_refund_policy">
                    Cancellation & Refund Policy
                  </Link>
                </p>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Sidebar;
