import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./postSearch/searchSlice";
import tagReducer from "./tagFilter/tagSlice";
import postReducer from "./postEdit/postSlice";
import activeNotificationReducer from "./activeNotifications/activeNotificationSlice";
import languageReducer from "./languageSlice";
import userSearchReducer from "./userSearch/userSearchSlice";
import chatReducer from "./chat/chatSlice";
const store = configureStore({
  reducer: {
    search: searchReducer,
    tag: tagReducer,
    chat: chatReducer,
    post: postReducer,
    activeNotifications: activeNotificationReducer,
    language: languageReducer,
    userSearch: userSearchReducer,
  },
});

export default store;
