import React from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";

const PaymentModal = ({
  paymentForm,
  closeModal,
  textsExtra,
  formData,
  handleInputChange,
  handleFormSubmit,
  card,
}) => {
  return (
    <Modal
      open={paymentForm}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: 50 }}
      width={700}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="flex flex-col w-full p-5">
        <div className="lg:w-1/2 md:w-1/2 hidden lg:block md:block">
          <div className="flex space-x-3 items-center mb-5">
            <FaCheckCircle className="text-5xl text-gray-700" />
            <h3 className="text-lg font-extrabold">
              {textsExtra.subscribeAccess}
            </h3>
          </div>
          <span className="text-2xl font-extrabold text-orange-500">
            {textsExtra.annualPrice}
          </span>
        </div>

        <div className="w-full space-y-3">
          <h3 className="text-lg font-extrabold mt-3 mb-2">
            {textsExtra.paywithcard}
          </h3>
          <div>
            <label className="font-bold">Email</label>
            <input
              type="text"
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <label className="font-bold">Card Information</label>
            <div className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto flex">
              <input
                type="text"
                className="rounded-full outline-none w-2/3 h-auto"
                placeholder="1234-1234-1234"
                value={formData.cardNumber}
                onChange={handleInputChange}
              />
              <img src={card} alt="cardlogo" className="ml-auto" />
            </div>
            <div className="mt-2 flex space-x-2">
              <input
                type="text"
                className="rounded-full outline-none border-gray-500 border-2 p-2 w-1/2 h-auto"
                placeholder="MM-YYYY"
                value={formData.expiryDate}
                onChange={handleInputChange}
              />
              <div className="rounded-full outline-none border-gray-500 border-2 p-2 w-1/2 h-auto flex">
                <input
                  type="text"
                  className="rounded-full outline-none w-2/3 h-auto"
                  value={formData.cvv}
                  onChange={handleInputChange}
                  placeholder="cvv"
                />
                <CiCreditCard1 className="text-2xl ml-auto" />
              </div>
            </div>
          </div>

          <div>
            <label className="font-bold">Name on card</label>
            <input
              type="text"
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              value={formData.cardHolderName}
              onChange={handleInputChange}
            />
          </div>

          <div className="space-y-2">
            <label className="font-bold">Billing Address</label>
            <select
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              // value={formData.country}
              // onChange={handleSelectChange}
            >
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="Canada">Canada</option>
              <option value="Australia">Australia</option>
            </select>
            <input
              type="text"
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              placeholder="Address Line 1"
              value={formData.billingAddress}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              placeholder="Address Line 2"
              value={formData.addressLinetwo}
              onChange={handleInputChange}
            />
            <div className="mt-2 flex space-x-2">
              <input
                type="text"
                className="rounded-full outline-none border-gray-500 border-2 p-2 w-1/2 h-auto"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className="rounded-full outline-none border-gray-500 border-2 p-2 w-1/2 h-auto"
                placeholder="Zip"
                value={formData.zip}
                onChange={handleInputChange}
              />
            </div>
            <input
              type="text"
              className="rounded-full outline-none border-gray-500 border-2 p-2 w-full h-auto"
              placeholder="state"
              value={formData.state}
              onChange={handleInputChange}
            />
            <button
              className="rounded-full bg-[#404041] text-white font-bold border-2 p-2 w-full h-auto"
              onClick={handleFormSubmit}
            >
              Pay $9.99
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
