import React, { useState } from "react";
import { Modal, Input } from "antd";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { IoClose } from "react-icons/io5";

const UserNameModal = ({ closeModal }) => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleSave = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");
      await axios.post(
        `${baseURL}/api/v1/user/update/${userId}`,
        {
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast("Username updated successfully!", {
        style: {
          backgroundColor: "#333", // Gray background
          color: "#fff", // White text
        },
      });

      closeModal();
    } catch (error) {
      toast.error("Failed to update Username. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-gray-700 font-bold">Change Username</h1>
        <Input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full border-2 border-gray-300 rounded-full py-4 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
          placeholder="Enter new username"
        />

        <button
          className={`w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleSave}
          disabled={loading}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default UserNameModal;
