import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { FaRegCircleXmark } from "react-icons/fa6";
import { MdNavigateNext } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Explore from "../components/Explore";
import Notification from "../components/notifcation/Notification";
import HomeMain from "../components/homepage/HomeMain";
import translations from "../languages";
import MessageMain from "../components/messagePage/MessageMain";
import BookmarkMain from "../components/bookmark/BookmarkMain";
import SubscriptionMain from "../components/Subscription/SubscriptionMain";
import ProfileMain from "../profile/ProfileMain";
import { MdNotificationsActive } from "react-icons/md";
import SettingsMain from "../components/settings/SettingsMain";
import { Toaster, toast } from "sonner";
import ExploreMain from "../components/ExplorePage/ExploreMain";
import UserProfile from "../components/userProfile/UserProfile";
import HomeIcon from "../assets/homenewbig.svg";
import ExploreIcon from "../assets/radix-icons_magnifying-glass@2x.svg";
import MessagesIcon from "../assets/Message.svg";
import BookmarksIcon from "../assets/Bookmarks.svg";
import SubscriptionIcon from "../assets/Subscription.svg";
import ProfileIcon from "../assets/Profile.svg";
import SettingsIcon from "../assets/Settings.svg";
import SignOutIcon from "../assets/Signout.svg";
import BellIcon from "../assets/Bell.svg";
import BellIconActive from "../assets/Bellactive.svg";
import "./animations.css";
import mobilebarsvg from "../assets/barsvgmobtab.svg";
import Calendericon from "../assets/CalendarDots.svg";
import Calendericonactive from "../assets/CalendarDots (1).svg";
import HomeActive from "../assets/houseactivenew.svg";
import ExploreActive from "../assets/radix-icons_magnifying-glass oranage@2x.svg";
import MessagesActive from "../assets/Messageactive.svg";
import BookmarkActive from "../assets/Mediamodifier-Design.svg";
import SubscriptionActive from "../assets/Subscriptionactive.svg";
import ProfileActive from "../assets/profileactivenew.svg";
import SettingsActive from "../assets/settingsactivenew.svg";
import SignOutActive from "../assets/signoutactivenew.svg";
import PleaseLoginModal from "./PleaseLoginModal";
import ChatComponent from "../components/messagePage/ChatComponent";
import SideBarNotification from "../components/sidebarNotification/SideBarNotification";
import LogoutModal from "./LogoutModal";
import LoginLoader from "../components/loading/LoginLoader";
import logo from "../assets/image 6.png";
import commentIcon from "../assets/comment.svg";
import commentIconActive from "../assets/commentactive.svg";
import Sidebar from "../components/mini-components/Sidebar";
import Bottomnavigation from "../components/mini-components/Bottomnavigation";
import {
  setSearchText,
  clearSearchText,
} from "../redux-store/postSearch/searchSlice";
import SharedPost from "../components/share/SharedPost";
import DirectChat from "./DirectChat";
import Calender from "./Calender";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomeExperimental = () => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const sidebarOption = translations[selectedLanguage].sideBar;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationSideBar, setNotificationSideBar] = useState(false);
  const [activeComponent, setActiveComponent] = useState("Home");
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [directLoginClick, setDirectLoginClick] = useState(false);
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("");
  console.log(currentRoute, "currentRoute");

  const navigate = useNavigate();
  const location = useLocation();
  const activeNotificationCount = useSelector(
    (state) => state.activeNotifications.activeNotificationCount
  );
  const contentRef = useRef(null);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/":
        setActiveComponent(sidebarOption.Home);
        break;
      case "/explore":
        setActiveComponent(sidebarOption.Explore);
        break;
      case "/messages":
        setActiveComponent(sidebarOption.Messages);
        break;
      case "/bookmarks":
        setActiveComponent(sidebarOption.Bookmarks);
        break;
      case "/notification":
        setActiveComponent(sidebarOption.Notifications);
        break;
      case "/subscription":
        setActiveComponent(sidebarOption.Subscription);
        break;
      case "/profile":
        setActiveComponent(sidebarOption.Profile);
        break;
      case "/settings":
        setActiveComponent(sidebarOption.Settings);
        break;
      case "/calender":
        setActiveComponent(sidebarOption.Calender);
        break;
      case "/login":
        setActiveComponent(sidebarOption.LogIn);
        break;
      default:
        setActiveComponent(sidebarOption.Home);
        break;
    }
  }, [location.pathname]);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  const navigation = [
    {
      name: sidebarOption.Home,
      href: "/",
      icon: HomeIcon,
      iconActive: HomeActive,
      component: HomeMain,
      current: activeComponent === sidebarOption.Home,
    },
    {
      name: sidebarOption.Explore,
      href: "/explore",
      icon: ExploreIcon,
      iconActive: ExploreActive,
      component: ExploreMain,
      current: activeComponent === sidebarOption.Explore,
    },
    {
      name: sidebarOption.Profile,
      href: "/profile",
      icon: ProfileIcon,
      iconActive: ProfileActive,
      component: ProfileMain,
      current: activeComponent === sidebarOption.Profile,
    },
    {
      name: sidebarOption.Messages,
      href: "/messages",
      icon: commentIcon,
      iconActive: commentIconActive,
      component: MessageMain,
      current: activeComponent === sidebarOption.Messages,
    },
    {
      name: sidebarOption.Bookmarks,
      href: "/bookmarks",
      icon: BookmarksIcon,
      iconActive: BookmarkActive,
      component: BookmarkMain,
      current: activeComponent === sidebarOption.Bookmarks,
    },
    {
      name: sidebarOption.Notifications,
      href: "/notification",
      icon: BellIcon,
      iconActive: BellIconActive,
      component: SideBarNotification,
      current: activeComponent === sidebarOption.Notifications,
    },
    {
      name: sidebarOption.Subscription,
      href: "/subscription",
      icon: SubscriptionIcon,
      iconActive: SubscriptionActive,
      component: SubscriptionMain,
      current: activeComponent === sidebarOption.Subscription,
    },
    {
      name: sidebarOption.Calender,
      href: "/calender",
      icon: Calendericon,
      iconActive: Calendericonactive,
      component: Calender,
      current: activeComponent === sidebarOption.Calender,
    },

    {
      name: sidebarOption.Settings,
      href: "/settings",
      icon: SettingsIcon,
      iconActive: SettingsActive,
      component: SettingsMain,
      current: activeComponent === sidebarOption.Settings,
    },

    {
      name: userExists ? sidebarOption.LogOut : sidebarOption.LogIn,
      // href: "/login",
      icon: userExists ? SignOutIcon : SignOutIcon,
      iconActive: userExists ? SignOutActive : SignOutActive,
      component: HomeMain,
      current:
        activeComponent ===
        (userExists ? sidebarOption.LogOut : sidebarOption.LogIn),
    },
  ];

  const explorePages = [
    // sidebarOption.Home,
    sidebarOption.Explore,
    // sidebarOption.Messages,
    // sidebarOption.Bookmarks,
  ];
  const shouldRenderExplore = explorePages.includes(activeComponent);

  const handleNavigationClick = (name, href) => {
    console.log("clicked");

    // Clear search text on every navigation
    dispatch(clearSearchText());

    // Smoothly scroll to the top of the content area
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (
      !userExists &&
      name !== sidebarOption.Home &&
      name !== sidebarOption.Explore &&
      name !== sidebarOption.LogIn &&
      name !== sidebarOption.Settings
    ) {
      setIsLoginModalVisible(true);
    } else {
      if (name === sidebarOption.LogOut) {
        console.log("logout");
        setIsLogOutModalVisible(true);
      } else if (name === sidebarOption.LogIn) {
        console.log("redirecting to login");
        setDirectLoginClick(true);
      } else {
        console.log("fail");
        setActiveComponent(name);
        setSidebarOpen(false);
      }
    }
  };
  const toggleNotificationSideBar = () => {
    setNotificationSideBar((prevState) => !prevState);
  };

  return (
    <>
      {" "}
      <div className="w-full fade-in-slow h-screen flex  items-center largecenter medstart justify-start overflow-hidden">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          logo={logo}
          navigate={navigate}
          navigation={navigation}
          handleNavigationClick={handleNavigationClick}
          activeNotificationCount={activeNotificationCount}
        />

        <div className="medwidth largewidth bg-white h-screen flex flex-col px-5 md:px-5  sidebar-complete-hide border-r-2">
          <div className="flex flex-1 flex-col mt-10 ml-auto">
            <img
              src={logo}
              alt="logo"
              className="w-52 mt-1 cursor-pointer hidefrommed"
              onClick={() => navigate("/")}
            />
            <img
              src={mobilebarsvg}
              alt="logo"
              className="w-8 mt-1 cursor-pointer  hidelarge"
              onClick={() => setSidebarOpen(true)}
            />
            <ul
              role="list"
              className="flex  flex-col gap-y-7 mt-14 items-start"
            >
              <li>
                <ul role="list" className="-mx-2 space-y-2 font-bold ">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => handleNavigationClick(item.name)}
                        className={classNames(
                          item.current
                            ? " text-[#E7831D] relative"
                            : item.name === "Login"
                            ? "text-[#E7831D] font-bold hover:text-[#E7831D] "
                            : "text-[#404041] hover:text-[#E7831D]",
                          "group flex gap-x-3 rounded-md p-2 text-lg leading-7 text-[15px] font-light"
                        )}
                      >
                        {item.name === "Notifications" &&
                          activeNotificationCount > 0 && (
                            <div className="flex justify-center items-center absolute">
                              <span className="bg-[#E7831D] w-5 h-5 rounded-full animate-spin absolute filter z-40"></span>
                              <span className="rounded-full absolute text-white z-50 flex justify-center items-center text-sm">
                                {activeNotificationCount}
                              </span>
                            </div>
                          )}
                        {item.name === "Notificaciones" &&
                          activeNotificationCount > 0 && (
                            <div className="flex justify-center items-center absolute">
                              <span className="bg-[#E7831D] w-5 h-5 rounded-full animate-spin absolute filter z-40"></span>
                              <span className="rounded-full absolute text-white z-50 flex justify-center items-center text-sm">
                                {activeNotificationCount}
                              </span>
                            </div>
                          )}

                        <SvgIcon
                          src={item.current ? item.iconActive : item.icon}
                          className="h-6 w-6 shrink-0 group-hover:hidden"
                          aria-hidden="true"
                        />
                        <SvgIcon
                          src={item.iconActive}
                          className="h-6 w-6 shrink-0 hidden group-hover:block"
                          aria-hidden="true"
                        />
                        <span
                          className={
                            item.current
                              ? "font-bold text-lg hidefrommed"
                              : " text-lg hidefrommed"
                          }
                        >
                          {item.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
          <p className="relative  hidefrommed bg-white h-10 bottom-4  flex text-left items-center  left-10  w-52 justify-center mt-4  z-50 text-md text-gray-600 text-[.6rem]">
            <a href="https://www.bocaenboca.com" target="_blank">
              {" "}
              Boca En Boca is a trademark of The Peddler Group, Inc. Copyright
              2022.
            </a>
          </p>
          <p className="relative  hidefrommed bg-white h-10 flex-wrap  flex text-left items-center  left-10  w-52 mt-1  z-50 text-md text-gray-600 text-[.6rem] bottom-4">
            <Link to="/set/policy/terms_and_conditions">
              Terms & Conditions{" "}
            </Link>
            <Link to="/set/policy/privacy_policy" className="ml-1">
              {" "}
              Privacy Policy
            </Link>
            <Link to="/set/policy/cancellation_&_refund_policy">
              Cancellation & Refund Policy
            </Link>
          </p>
        </div>
        <div
          ref={contentRef}
          className="mt-[1.1rem] bg-white w-[700px] rounded-md h-screen overflow-y-auto scrollbar-hide "
        >
          <Routes>
            <Route
              path="/"
              element={<HomeMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/post/:id"
              element={<SharedPost setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/explore"
              element={<ExploreMain eMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/messages"
              element={<MessageMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/message-for-post/:id"
              element={<MessageMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/bookmarks"
              element={<BookmarkMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/subscription"
              element={<SubscriptionMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/profile"
              element={<ProfileMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/settings"
              element={<SettingsMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/signout"
              element={<HomeMain setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/user/:id"
              element={<UserProfile setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/calender"
              element={<Calender setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/notification"
              element={<SideBarNotification setSidebarOpen={setSidebarOpen} />}
            />
            <Route
              path="/dm/:id/:userId"
              element={<DirectChat setSidebarOpen={setSidebarOpen} />}
            />
          </Routes>

          {/* New Policy Links */}
        </div>
        <span className="borer-2 h-screen w-[0.1rem] border-black bg-gray-200"></span>
        <div className="w-96   rounded-md h-screen hidenotification">
          {/* {notificationSideBar && (
            <aside className="f inset-y-0 right-0 lg:w-80 md:w-72  border-l border-gray-200 px-1 py-2 sm:px-6 lg:px-3 xl:block bg-white z-50 mt-14">
              <div className="sticky mb-2 ">
                {shouldRenderExplore && (
                  <Explore activeComponent={activeComponent} />
                )}
              </div>

              {!shouldRenderExplore && <div className=""></div>}
              <div className=" max-h-screen scrollbar-hide  bg-transparent">
                {shouldRenderExplore && <Notification list={3} />}
                {!shouldRenderExplore && <Notification list={3} />}
              </div>
            </aside>
          )} */}

          <aside className="scrollbar-hide bg-transparent    lg:w-96 md:w-96  z-50 border-gray-200 px-1   sm:px-6 lg:px-3 bg-white  scrollbar-hie">
            {currentRoute !== "/notification" && (
              <div className=" mb-2 relative h-screen mt-[2rem] ">
                {shouldRenderExplore && (
                  <Explore activeComponent={activeComponent} />
                )}
                {shouldRenderExplore && (
                  <Notification list={6} height={"105vh"} />
                )}
                {!shouldRenderExplore && (
                  <Notification list={6} height={"120vh"} />
                )}
              </div>
            )}

            {shouldRenderExplore && <div className="">{/* <hr /> */}</div>}

            {!shouldRenderExplore && <div className=""></div>}

            <div className="scrollbar-hide bg-transparent"></div>
          </aside>
        </div>
      </div>{" "}
      <Bottomnavigation
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        logo={logo}
        navigate={navigate}
        navigation={navigation}
        handleNavigationClick={handleNavigationClick}
        activeNotificationCount={activeNotificationCount}
      />
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
      {isLogOutModalVisible && (
        <LogoutModal
          visible={isLogOutModalVisible}
          onCancel={() => setIsLogOutModalVisible(false)}
        />
      )}
      {directLoginClick && <LoginLoader />}
    </>
  );
};

export default HomeExperimental;
