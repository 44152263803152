import React from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import { IoClose } from "react-icons/io5";
const LogoutModal = ({
  visible,

  onCancel,
  title = "Please Log In",
  content = "You need to log in to access this feature.",
}) => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].logoutmodal;
  const LogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={null}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[27rem]">
        <p class="font-inter font-semibold text-2xl leading-7 text-center px-10 py-5 mt-5">
          {texts.text}
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
        />
        <div className="w-full flex gap-5 px-10">
          <button
            className="p-3 bg-gray-50 text-[#404041] font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-1/2 lg:mt-4 mt-0 border-2 border-[#404041]"
            onClick={onCancel}
          >
            {texts.cancel}
          </button>
          <button
            className="p-3 bg-[#404041] text-white font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-1/2 lg:mt-4 mt-0"
            onClick={LogOut}
          >
            {texts.confirm}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
