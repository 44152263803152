import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Button, Modal } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import card from "../../assets/mastercardlogo.png";
import { CiCreditCard1 } from "react-icons/ci";
import BigLogo from "../../assets/mainlogonew.png";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import { IoClose } from "react-icons/io5";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import PaymentModal from "./PaymentModal";
import axios from "axios";
import SubmitModal from "./SubmitModal";
import SubscriptionCard from "./SubscriptionCard";
import CardShimmer from "./CardShimmer";
const SubscriptionMain = ({ setSidebarOpen }) => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  console.log(subscriptions, "subscriptions");
  const [error, setError] = useState(null);

  const texts = translations[selectedLanguage].headings;
  const textsExtra = translations[selectedLanguage].subscriptionTexts;
  const buttons = translations[selectedLanguage].buttons;
  const [paymentForm, setPaymentForm] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    cardHolderName: "",
    billingAddress: "",
    addressLinetwo: "",
    city: "",
    zip: "",
    state: "",
    country: "USA",
  });

  const toggleFormPayment = () => {
    setPaymentForm(!paymentForm);
  };

  const closeModal = () => {
    setPaymentForm(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = () => {
    console.log("Form data:", formData);

    closeModal();
    setSubmitModal(true);
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/subsription/list?limit=10&offset=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubscriptions(response.data.content.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);
  return (
    <>
      <div className="scrollbar-hide w-auto paddingy mobile-top ">
        <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
          <div className="absolute left-0">
            <img
              src={mobilebarsvg}
              alt="logo"
              className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
              onClick={() => setSidebarOpen(true)}
            />
          </div>
          <div className="flex justify-center w-full">
            <img src={bblogo} className="h-8 hidelarge" />
          </div>
        </div>

        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {texts.subscription}
        </h1>
        <hr />
        {loading ? (
          <>
            <CardShimmer />
            <CardShimmer />
          </>
        ) : (
          <SubscriptionCard
            subscriptions={subscriptions}
            textsExtra={textsExtra}
            buttons={buttons}
            toggleFormPayment={toggleFormPayment}
          />
        )}
      </div>

      <PaymentModal
        paymentForm={paymentForm}
        closeModal={closeModal}
        textsExtra={textsExtra}
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        card={card}
      />
      <SubmitModal
        submitModal={submitModal}
        setSubmitModal={setSubmitModal}
        textsExtra={textsExtra}
        BigLogo={BigLogo}
      />
    </>
  );
};

export default SubscriptionMain;
