import React from "react";
import TabsNavigation from "./TabsNavigation";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
const BookmarkMain = ({ setSidebarOpen }) => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  return (
    <div className="scrollbar-hide w-auto  paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide ">
        {texts.bookmarks}
      </h1>
      <hr />
      <TabsNavigation />
    </div>
  );
};

export default BookmarkMain;
