import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoBookmarkSharp } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Spin } from "antd"; // Import AntD Spin component
import avatar from "../../assets/lady.jpg";
import NoDataFound from "../NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import { Link } from "react-router-dom";
import NotiLoader from "../loading/NotiLoader";

const ProfileTab = () => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].actions;
  const [bookmarkData, setBookmarkData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removingBookmark, setRemovingBookmark] = useState(null); // Track which bookmark is being removed

  // Function to fetch bookmarked data
  const fetchBookmarkData = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/user_profile_list?limit=10&offset=1`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setBookmarkData(response.data.content.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    fetchBookmarkData();
  }, []);

  // Function to remove a bookmark
  const removeBookmark = async (id) => {
    setRemovingBookmark(id); // Set the loading state for this bookmark
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Fetch updated bookmark list after removal
      fetchBookmarkData();
    } catch (error) {
      console.error("Error removing bookmark:", error);
    } finally {
      setRemovingBookmark(null); // Reset the loading state
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="w-full py-5">
      <div>
        {loading ? (
          <div className="flex justify-center flex-col">
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader />
            <NotiLoader /> {/* Show AntD Spin while fetching data */}
          </div>
        ) : bookmarkData.length > 0 ? (
          bookmarkData.map((post, index) => (
            <div
              key={index}
              className="px-10 py-3 mb-3 bg-white rounded-xl  w-full scrollbar-hide hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              <div className="flex">
                <Link to={`/user/${post.user.id}`}>
                  <img
                    className={`w-12 h-12 rounded-full p-0 object-cover ${
                      post.user.type === "job-seeker"
                        ? "ring-2 ring-gray-700"
                        : "ring-2 ring-[#E7831D]"
                    }`}
                    src={
                      post.user.profile_image !== "default.png" &&
                      post.user.profile_image
                        ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user.profile_image}`
                        : avatar
                    }
                    alt="User Avatar"
                  />
                </Link>

                <div className="flex flex-col ml-2">
                  <Link to={`/user/${post.user.id}`}>
                    <span className="text-lg font-bold text-gray-600">
                      {post.user.first_name} {post.user.last_name}
                    </span>
                  </Link>

                  <div className="flex space-x-3">
                    {post.user.username}
                    <span
                      className={`text-sm flex items-center ml-2 ${
                        post.user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(post.user.type)}
                      <IoIosCheckmarkCircle className="ml-1" />
                    </span>
                  </div>
                </div>

                <div className="ml-auto flex items-center">
                  {removingBookmark === post.user.id ? (
                    <Spin size="medium" /> // Show Spin while removing bookmark
                  ) : (
                    <IoBookmarkSharp
                      className="text-2xl cursor-pointer text-gray-900"
                      onClick={() => removeBookmark(post.user.id)} // Remove bookmark on icon click
                    />
                  )}
                </div>
              </div>

              <div className="flex px-3 w-full mt-3 ml-3"></div>
              <hr className="mt-3" />
            </div>
          ))
        ) : (
          <div className="p-3 bg-white rounded-xl mb-3 w-full scrollbar-hide flex justify-center items-center text-center h-96">
            No profile bookmarked
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileTab;
