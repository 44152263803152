import React, { useState } from "react";
import { FaRegEdit, FaUserCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { LuShare } from "react-icons/lu";
import { CiLocationOn } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import lady from "../assets/lady.jpg";
import axios from "axios"; // Import Axios
import profileicon from "../assets/Profile.svg";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";
import { motion } from "framer-motion";
import BookmarksIcon from "../assets/Bookmarks.svg";
import { setSearchText } from "../redux-store/postSearch/searchSlice";
import ShareProfilePopUp from "../components/mini-components/ShareProfilePopUp";
import nobanner from "../assets/nocover.png";
import { IoBookmarkSharp } from "react-icons/io5";
import { FaRegBookmark } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const UserProfileBanner = ({
  userDetails,
  isMyProfile,
  handleBookmark,
  handleDeleteBookmark,
  bookmarkLoading,
}) => {
  console.log(userDetails, "userDetails");
  const selectedLanguage = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const texts = translations[selectedLanguage].profileExtras;
  const [profileShareModalVisible, setProfileShareModalVisible] =
    useState(false);
  const [profileUrl, setprofileUrl] = useState("");
  const [isImageOverlayVisible, setImageOverlayVisible] = useState(false);
  const [bookMarkFetching, setBookMarkFetching] = useState(false);
  const handleshare = (id) => {
    setprofileUrl(`${window.location.origin}/user/${id}`);
    setProfileShareModalVisible(true);
    // navigate(`/post/${id}`);
  };
  function formatResponseTime(seconds) {
    const days = Math.ceil(seconds / (24 * 60 * 60));
    const hours = Math.ceil((seconds % (24 * 60 * 60)) / (60 * 60));

    if (days > 30) {
      return "over a month";
    }
    let formatted = "";
    if (days > 0) {
      formatted += `${days} day${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      formatted += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    return formatted ? `${formatted.trim()} ${texts.responseTime}` : "";
  }
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };
  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };
  const handleProfileImageClick = () => {
    setImageOverlayVisible(true);
  };

  const closeOverlay = () => {
    setImageOverlayVisible(false);
  };
  const parseTextWithHash = (text) => {
    if (!text.includes("#")) {
      return text;
    }
    const parts = text.split(/(#\S+)/g);
    return parts.map((part, index) =>
      part.startsWith("#") ? (
        <span
          key={index}
          className="text-[#E7831D] font-bold cursor-pointer"
          onClick={() => handleTagClick(part)}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  const handleBookMarkClick = async () => {
    setBookMarkFetching(true);
    if (userDetails.bookmark_status === "yes") {
      await handleDeleteBookmark(userDetails.id);
    } else {
      await handleBookmark(userDetails.id);
    }
    setBookMarkFetching(false);
  };
  return (
    <>
      <div className="mb-2">
        <div className="relative">
          <img
            className="h-32 w-full object-cover lg:h-48 "
            src={
              userDetails.banner_image !== "default.png" &&
              userDetails.banner_image
                ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.banner_image}`
                : nobanner
            }
            alt=""
            style={{
              filter: "grayscale(100%)",
            }}
          />
        </div>
        <div className="mx-auto ml-8 max-w-full px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex relative">
              {userDetails.type === "job-seeker" && (
                <img
                  className=" rounded-full  h-32 w-32 object-cover ring-4 ring-gray-500"
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                  alt=""
                  onClick={handleProfileImageClick}
                />
              )}
              {userDetails.type === "employer" && (
                <img
                  className=" rounded-full  h-32 w-32 object-cover ring-4 ring-[#E7831D] "
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                  alt=""
                  onClick={handleProfileImageClick}
                />
              )}
            </div>

            {userDetails?.profile_image === null ||
              (userDetails?.profile_image === "default.png" && (
                <div className="flex relative">
                  <FaUserCircle
                    className={`h-32 w-32 bg-white text-[2rem] rounded-full p-0 object-cover text-gray-300 cursor-pointer ${
                      userDetails?.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                    onClick={() => navigate(`/user/${userDetails?.id}`)}
                  />
                </div>
              ))}
          </div>
          <div className="mt-3  min-w-0 flex-1 ">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {userDetails.first_name} {userDetails.last_name}
            </h1>
          </div>
          <div className="flex items-center">
            <span className="text-sm text-gray-600">
              {userDetails.username}
            </span>
            {userDetails.type === "employer" && (
              <span className="text-sm text-[#E7831D]   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}{" "}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            {userDetails.type === "job-seeker" && (
              <span className="text-sm text-gray-600   flex items-center ml-2">
                {capitalizeFirstLetter(userDetails.type)}
                <FaCheckCircle className="ml-2" />
              </span>
            )}
            <div className="ml-auto flex  justify-center items-center gap-6">
              {!isMyProfile && (
                <>
                  {bookMarkFetching ? (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      className="ml-auto w-2/4 text-gray-500"
                    />
                  ) : (
                    <>
                      {userDetails.bookmark_status === "yes" ? (
                        <IoBookmarkSharp
                          className="text-2xl cursor-pointer hover:scale-105"
                          onClick={handleBookMarkClick}
                        />
                      ) : (
                        <FaRegBookmark
                          className="text-2xl cursor-pointer hover:scale-105"
                          onClick={handleBookMarkClick}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              <LuShare
                className="text-2xl cursor-pointer hover:scale-105"
                onClick={() => handleshare(userDetails.id)}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-3 mt-1 ">
            <div className="flex gap-3 text-gray-700 mb-3">
              {userDetails.no_of_post} {texts.posts}{" "}
              <span>
                {" "}
                {userDetails.no_of_bookmark} {texts.bookmarks}
              </span>{" "}
              {userDetails.avg_chat_response !== null && (
                <span>{formatResponseTime(userDetails.avg_chat_response)}</span>
              )}
            </div>
            {userDetails.city && (
              <div className="flex ">
                <CiLocationOn className="text-xl mr-2 " />
                {userDetails.city && userDetails.city.name}
                {", "}
                {userDetails.state && userDetails.state.name}
                {", "}
                {userDetails.country && userDetails.country.name}
              </div>
            )}

            {userDetails.about_me && (
              <div className="flex ">
                <img
                  src={profileicon}
                  alt="profile icon"
                  className="mr-2 h-5 w-5"
                />
                {texts.about}
              </div>
            )}
            {userDetails.about_me && (
              <p className="mx-7">{parseTextWithHash(userDetails.about_me)}</p>
            )}
          </div>
        </div>
      </div>
      {isImageOverlayVisible && (
        <motion.div
          className="fixed inset-0 z-[99999] flex items-center flex-col  bg-gray-700 bg-opacity-30 backdrop-blur-md"
          onClick={closeOverlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="relative"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <img
              className="h-52 w-52 md:h-72 md:w-72 lg:h-72 lg:w-72 rounded-full object-cover mt-24"
              src={
                userDetails.profile_image !== "default.png" &&
                userDetails.profile_image
                  ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoHAKWHhA6UuX-UPdizhi5K4IsByBpEJMX8Q&s"
              }
              alt="Profile"
            />
          </motion.div>
          <motion.div
            className="relative lg:w-96 md:w-72 w-64"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.9, ease: "easeInOut" }}
          >
            <div className="absolute mt-10 left-1/2 w-full transform -translate-x-1/2 flex  gap-4 p-2 md:p-5 lg:p-3 bg-white rounded-xl shadow-lg">
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-gray-400 rounded-full">
                  <IoQrCodeOutline
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-gray-700 font-semibold text-sm "
                  onClick={() => handleshare(userDetails.id)}
                >
                  QR Code
                </button>
              </div>
              <div className="flex items-center flex-col justify-center gap-2 w-1/2">
                <div className="p-2 border-2 border-gray-400 rounded-full">
                  <LuShare
                    className="text-[#E7831D] text-3xl cursor-pointer hover:scale-105"
                    onClick={() => handleshare(userDetails.id)}
                  />
                </div>

                <button
                  className="text-gray-700 font-semibold text-sm"
                  onClick={() => handleshare(userDetails.id)}
                >
                  Share Profile
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
      {profileShareModalVisible && (
        <ShareProfilePopUp
          profileShareModalVisible={profileShareModalVisible}
          setProfileShareModalVisible={setProfileShareModalVisible}
          profileUrl={profileUrl}
        />
      )}
    </>
  );
};

export default UserProfileBanner;
