import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import ReceiveingMessage from "./chatbox/ReceiveingMessage";
import SendingMessage from "./chatbox/SendingMessage";

const SingleChat = ({ conversationData }) => {
  const storedUserId = localStorage.getItem("userId");
  const endOfMessagesRef = useRef(null);
  const [messageCount, setMessageCount] = useState(conversationData.length);
  const [currentDate, setCurrentDate] = useState(moment.utc());

  // Update current date every 24 hours
  useEffect(() => {
    const updateDate = () => setCurrentDate(moment.utc());
    const interval = setInterval(updateDate, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    // Initial update on component mount
    updateDate();

    return () => clearInterval(interval);
  }, []);

  // Check if conversationData exists and is an array before mapping
  if (!conversationData || !Array.isArray(conversationData)) {
    return (
      <div className="h-[27rem] flex justify-center items-center text-orange-300">
        No messages yet
      </div>
    );
  }

  // useEffect that scrolls to the bottom when the message count increases
  useEffect(() => {
    if (conversationData.length > messageCount) {
      endOfMessagesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      setMessageCount(conversationData.length);
    }
  }, [conversationData, messageCount]);

  // Render messages with date and time headers when the day changes
  return (
    <div className="h-[100vh] overflow-y-auto scrollbar-hide">
      {conversationData.map((chat, index) => {
        const chatDate = moment(chat.updated_at); // Keep date in UTC
        const isNewDay =
          index === 0 ||
          chatDate.isAfter(
            moment(conversationData[index - 1].updated_at),
            "day"
          );

        return (
          <React.Fragment key={index}>
            {isNewDay && (
              <div className="w-72 py-1 bg-[#F2F2F2] rounded-full mx-auto text-center text-sm text-[#9B9B9B]">
                {chatDate.format("dddd, MMMM D, YYYY [at] h:mm A")}
              </div>
            )}
            {chat.user_id === storedUserId ? (
              <SendingMessage chat={chat} />
            ) : (
              <ReceiveingMessage chat={chat} />
            )}
          </React.Fragment>
        );
      })}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default SingleChat;
