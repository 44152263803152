import React from "react";

const NotificationShimmer = () => {
  return (
    <div className="flex flex-col gap-4 p-2 w-full h-auto bg-white">
      <span className="text-lg font-bold text-black mx-3 mt-3">
        {" "}
        Notifications
      </span>

      <div className="bg-gray-200 animate-pulse rounded-xl h-24 w-full"></div>
      <div className="bg-gray-200 animate-pulse rounded-xl h-24 w-full"></div>
      <div className="bg-gray-200 animate-pulse rounded-xl h-24 w-full"></div>
      {/* <div className="bg-gray-200 animate-pulse rounded-xl h-24 w-full"></div> */}
    </div>
  );
};

export default NotificationShimmer;
