import React from "react";
import WelcomeBack from "./WelcomeBack";
import logo from "../../assets/mainlogonew.png";
const AuthFormHeading = ({ heading, headingtwo }) => {
  return (
    <div>
      <h1 className="text-[#818181] text-2xl "> {heading}</h1>
      <img
        src={logo}
        alt="logo"
        className="lg:w-3/5 md:w-1/2 sm:w-full mx-auto mt-3"
      />
      <div className="flex space-x-2 mt-5 mb-2 justify-center items-center ">
        <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
        <div className="text-[#818181] text-sm ">{headingtwo}</div>
        <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
      </div>
    </div>
  );
};

export default AuthFormHeading;
