import React, { useEffect, useState } from "react";
import moment from "moment";
import { Toaster, toast } from "sonner";
import { Popover, Transition } from "@headlessui/react";
import avatar from "../../assets/lady.jpg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CiBookmark } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import NotiLoader from "../loading/NotiLoader";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import { IoIosBookmark } from "react-icons/io";
import { Spin } from "antd";
import axios from "axios";
import { setChatOpened } from "../../redux-store/chat/chatSlice";
import { Link } from "react-router-dom";
import { IoBookmark, IoBookmarkSharp } from "react-icons/io5";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import { FaRegBookmark } from "react-icons/fa6";
import CityModal from "../settings/settingsModals/CityModal";
import { TiPin } from "react-icons/ti";
import { BsPinFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import PostDescription from "../mini-components/PostDescription";
const ChatList = ({
  loading,
  messagedata,
  openChat,
  deleteChats,
  getChatList,
  pinToTheTopChats,
  blockOrReportUser,
  removePinFromTopChats,
}) => {
  const storedUserId = localStorage.getItem("userId");
  const [isBookmarkurl, setIsbookmarkedurl] = useState(false);

  const notichannelId = localStorage.getItem("notichannelId");
  const [chatOpened, setChatOpened] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const selectedLanguage = useSelector((state) => state.language.language);
  const [isMenuOpens, setIsMenuOpens] = useState(false);
  const [bookMarkFetching, setBookMarkFetching] = useState({});
  const [error, setError] = useState(null);
  const isChatOpened = useSelector((state) => state.chat.chatOpened);
  const texts = translations[selectedLanguage].headings;
  const options = translations[selectedLanguage].actions;

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("bookmarks")) {
      setIsbookmarkedurl(true);
    }
  }, []);
  const getTimeAgo = (created_at) => {
    const now = moment();

    const createdAt = moment(created_at);

    const monthsDiff = now.diff(createdAt, "months");
    const daysDiff = now.diff(createdAt, "days");
    const hoursDiff = now.diff(createdAt, "hours");
    const minutesDiff = now.diff(createdAt, "minutes");
    const secondsDiff = now.diff(createdAt, "seconds");

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    } else if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    } else if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    } else if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    } else {
      return `${secondsDiff} second${secondsDiff > 1 ? "s" : ""} ago`;
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatMessage = (message) => {
    return message.replace(
      /#(\w+)/g,
      '<span class="font-bold text-orange-400">#$1</span>'
    );
  };

  const truncateMessage = (message, length) => {
    if (message.length <= length || showFullMessage) return message;
    return `${message.slice(
      0,
      length
    )}... <span class="text-[#E7831D] cursor-pointer" onClick={() => setShowFullMessage(true)}>Read more</span>`;
  };

  const handleClick = (user, otherUser) => {
    const lastMessage =
      user.channel_last_conversation && user.channel_last_conversation.message
        ? user.channel_last_conversation.message
        : null;

    if (lastMessage) {
      localStorage.setItem("lastmsg", lastMessage);
    } else {
      localStorage.removeItem("lastmsg");
    }

    if (user.post && user.post.description) {
      localStorage.setItem("postDescription", user.post.description);
    } else {
      localStorage.removeItem("postDescription");
    }

    localStorage.setItem("channelId", otherUser.channel_id);

    openChat(otherUser, lastMessage);
  };

  useEffect(() => {
    if (notichannelId && !chatOpened) {
      messagedata.forEach((user) => {
        const otherUser = user.channel_users.find(
          (channelUser) => channelUser.user.id !== storedUserId
        );

        if (otherUser && otherUser.channel_id === notichannelId) {
          handleClick(user, otherUser);
          setChatOpened(true);
        }
      });
    }
  }, [notichannelId, messagedata, storedUserId, chatOpened]);
  const handleBookmarkChat = async (channelId) => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      setBookMarkFetching((prev) => ({ ...prev, [channelId]: true }));

      await axios.post(
        `${baseUrl}/api/v1/customer/chat/bookmark/store`,
        { channel_id: channelId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Chat bookmarked successfully");
      getChatList();
    } catch (error) {
      toast.error("Failed to bookmark chat");
    } finally {
      setBookMarkFetching((prev) => ({ ...prev, [channelId]: false }));
    }
  };
  const handleBookmarkRemove = async (channelId) => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      setBookMarkFetching((prev) => ({ ...prev, [channelId]: true }));

      await axios.delete(
        `${baseUrl}/api/v1/customer/chat/bookmark/delete/${channelId}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Chat bookmarked removed");
      getChatList();
    } catch (error) {
      toast.error("Failed to remove bookmark chat");
    } finally {
      setBookMarkFetching((prev) => ({ ...prev, [channelId]: false }));
    }
  };
  const handleAction = async (actionType, userId) => {
    try {
      const result = await blockOrReportUser(userId, actionType);
      console.log(`${actionType} action successful:`, result);
    } catch (error) {
      console.error(`Error performing ${actionType} action:`, error);
    }
  };
  return (
    <>
      {isMenuOpens && (
        <div className="fixed inset-0 bg-black/20 bg-opacity-50 z-50" />
      )}
      {loading ? (
        <div className="flex flex-col paddingy mobile-top">
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
          <NotiLoader />
        </div>
      ) : (
        <div className="paddingy mobile-top">
          {!isBookmarkurl && (
            <div>
              <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
                <div className="absolute left-0">
                  <img
                    src={mobilebarsvg}
                    alt="logo"
                    className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
                    onClick={() => setSidebarOpen(true)}
                  />
                </div>
                <div className="flex justify-center w-full">
                  <img src={bblogo} className="h-8 hidelarge" />
                </div>
              </div>
              {!chatOpened && (
                <div className=" sidebar-complete-hide">
                  <h1 className="text-lg font-bold mb-2 px-8">
                    {texts.messages}
                  </h1>
                  <hr />
                </div>
              )}
            </div>
          )}

          {messagedata.length === 0 ? (
            <div className="text-center py-8 text-[#E7831D] text-xl">
              {texts.noMessages || "0 messages"}
            </div>
          ) : (
            messagedata.map((user, index) => {
              const otherUser = user.channel_users.find(
                (channelUser) => channelUser.user.id !== storedUserId
              );
              const bookmarkStatus = user.bookmark_status;
              console.log(bookmarkStatus, "bookmarkStatus");
              if (!otherUser || !user.post) {
                return null;
              }

              const isBookmarked = bookmarkStatus.length > 0;

              return (
                <div
                  key={index}
                  onClick={() => handleClick(user, otherUser)}
                  className="py-6 bg-white rounded-xl mb-3 w-full scrollbar-hide mt-5 px-10 hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
                >
                  <div className="flex">
                    <Link to={`/user/${otherUser.user.id}`}>
                      {otherUser.user?.profile_image !== null &&
                      otherUser.user?.profile_image !== "default.png" ? (
                        <img
                          className={`w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] ${
                            otherUser.user.type === "employer"
                              ? "ring-2 ring-[#E7831D]"
                              : "ring-2 ring-gray-700"
                          }`}
                          src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${otherUser.user.profile_image}`}
                          alt="User Avatar"
                          style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                        />
                      ) : (
                        <div className="flex relative">
                          <FaUserCircle
                            className={`w-12 h-12 rounded-full p-0 object-cover text-gray-300 ${
                              otherUser.user?.type === "employer"
                                ? "ring-2 ring-[#E7831D]"
                                : "ring-2 ring-gray-700"
                            }`}
                            onClick={() =>
                              navigate(`/user/${otherUser.user?.id}`)
                            }
                          />
                        </div>
                      )}
                    </Link>

                    <div
                      className="flex flex-col ml-2 cursor-pointer"
                      onClick={() => handleClick(user, otherUser)}
                    >
                      <Link to={`/user/${otherUser.user.id}`}>
                        {" "}
                        <span className="text-md font-bold text-gray-600">
                          {otherUser.user.first_name} {otherUser.user.last_name}
                        </span>
                      </Link>

                      <span
                        className={`text-sm flex items-center ${
                          otherUser.user.type === "job-seeker"
                            ? "text-black"
                            : "text-[#E7831D]"
                        }`}
                      >
                        <span
                          className={`text-sm text-[#5B5B5B] ${
                            otherUser.user.username === "@premium" ||
                            otherUser.user.username === "@Test"
                              ? "text-[#5B5B5B] "
                              : ""
                          }`}
                        >
                          {otherUser.user.username}
                        </span>
                        <GoDotFill className="ml-1 text-xs mr-1" />
                        {capitalizeFirstLetter(otherUser.user.type)}
                        <IoIosCheckmarkCircle className="ml-1 text-lg" />
                      </span>
                    </div>

                    <div className="flex justify-end text-xs flex-col ml-auto relative top-0 ">
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              onClick={(event) => event.stopPropagation()}
                            >
                              <BsThreeDots className="text-3xl absolute bottom-4 left-7 cursor-pointer " />
                            </Popover.Button>
                            <Transition
                              enter="transition ease-out duration-75"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Popover.Panel className="rounded-md absolute w-auto top-0 right-0 z-50  origin-top-right  border border-black/30 bg-white  text-sm/6 shadow-md">
                                <div className="flex flex-col text-gray-900">
                                  <button
                                    className="group flex  items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevent chat from opening
                                      pinToTheTopChats(otherUser.channel_id);
                                    }}
                                  >
                                    {options.pinToTop}
                                  </button>
                                  <button
                                    className="group flex  items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleAction("block", otherUser.user_id);
                                    }}
                                  >
                                    {options.blockUser}
                                  </button>
                                  <button
                                    className="group flex  items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleAction("report", otherUser.user_id);
                                    }}
                                  >
                                    {options.reportUser}
                                  </button>
                                  <button
                                    className="group flex  items-left w-full px-2 py-2 text-sm hover:bg-gray-700 hover:text-white justify-start text-center whitespace-nowrap"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deleteChats(otherUser.channel_id);
                                    }}
                                  >
                                    {options.deleteChat}
                                  </button>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                      <p>{getTimeAgo(user.updated_at)}</p>
                    </div>
                  </div>

                  <PostDescription
                    description={user.post.description}
                    style={{ zIndex: 999999999999999 }}
                  />
                  <div onClick={() => handleClick(CityModal, otherUser)}></div>

                  <div className="flex px-3 w-full mt-3">
                    <div className="w-11/12">
                      {user.pin_to_top && (
                        <BsPinFill
                          className="ml-auto text-2xl text-gray-800 "
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent chat from opening
                            removePinFromTopChats(otherUser.channel_id);
                          }}
                        />
                      )}
                    </div>

                    <span className="cursor-pointer text-lg ml-auto mb-3">
                      {bookMarkFetching[otherUser.channel_id] ? (
                        <Spin size="medium" />
                      ) : isBookmarked ? (
                        <IoBookmarkSharp
                          className="text-2xl  ml-auto flex mb-3"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent chat from opening
                            handleBookmarkRemove(otherUser.channel_id);
                          }}
                        />
                      ) : (
                        <FaRegBookmark
                          className="text-2xl ml-auto flex mb-3"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent chat from opening
                            handleBookmarkChat(otherUser.channel_id);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <hr />
                </div>
              );
            })
          )}
        </div>
      )}
    </>
  );
};

export default ChatList;
