import { Fragment } from "react";
import moment from "moment";
import { TimePicker } from "antd"; // Import TimePicker from AntD
import { FaChevronDown } from "react-icons/fa";

const TimeDropdown = ({ selectedTime, setSelectedTime, heading }) => {
  const handleSelectTime = (time) => {
    if (time) {
      const formattedTime = time.format("HH:mm:ss");
      setSelectedTime(formattedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const handleOpenChange = (open) => {
    if (open) {
      setSelectedTime(null);
    }
  };

  return (
    <div className="relative inline-block w-full">
      <p className="text-[15.85px] font-bold mt-3 mb-1 text-[#424242]">
        {heading}
      </p>
      <TimePicker
        value={selectedTime ? moment(selectedTime, "HH:mm:ss") : null} // Convert to moment object if selectedTime is set
        onChange={handleSelectTime}
        onOpenChange={handleOpenChange}
        format="hh:mm A"
        className="w-full px-4 py-2 bg-white text-sm font-medium hover:border-gray-200  text-gray-700 hover:bg-gray-50 border-2 rounded-full focus:outline-none focus:ring-0 focus:border-gray-300"
        suffixIcon={<FaChevronDown className="text-gray-400" />}
        dropdownClassName="custom-dropdown" // Custom class for additional styling if needed
        allowClear={true}
      />
    </div>
  );
};

export default TimeDropdown;
