import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import translations from "../languages";
import bblogo from "../assets/276pxlogo.svg";
import mobilebarsvg from "../assets/barsvgmobtab.svg";
import MonthView from "../components/mini-components/MonthView";
import NotiLoader from "../components/loading/NotiLoader";

const Calender = ({ setSidebarOpen }) => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const options = ["Day view", "Month view"];
  const [paramStatusFilter, setParamStatusFilter] = useState("");
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [interviewList, setInterviewList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInterviewList = async () => {
      setLoading(true);
      const date = selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : moment();

      const fromDate = selectedDate ? date : moment().format("YYYY-MM-DD");
      const toDate = selectedDate
        ? date
        : moment().add(5, "months").format("YYYY-MM-DD");

      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/interview-list?channel_users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: {
              limit: "10",
              offset: 1,
              bookmark: "no",
              from_date: fromDate,
              to_date: toDate,
              ...(paramStatusFilter && { status: paramStatusFilter }),
            },
          }
        );
        setInterviewList(response.data?.content?.data || []);
      } catch (error) {
        console.error("Error fetching interview list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInterviewList();
  }, [selectedDate, paramStatusFilter]);

  const getEventsForDay = (day) => {
    const events = [];
    if (day.date() === 3 && day.month() === 0) {
      events.push({
        id: 1,
        name: "Design review",
        time: "10AM",
        datetime: day.clone().hour(10),
      });
      events.push({
        id: 2,
        name: "Sales meeting",
        time: "2PM",
        datetime: day.clone().hour(14),
      });
    } else if (day.date() === 12 && day.month() === 0) {
      events.push({
        id: 6,
        name: "Sam's birthday party",
        time: "2PM",
        datetime: day.clone().hour(14),
      });
    }
    return events;
  };

  return (
    <div className="scrollbar-hide w-auto flex flex-col paddingy mobile-top h-screen">
      <div className="h-1/2">
        <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
          <div className="absolute left-0">
            <img
              src={mobilebarsvg}
              alt="logo"
              className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
              onClick={() => setSidebarOpen(true)}
            />
          </div>
          <div className="flex justify-center w-full">
            <img src={bblogo} className="h-8 hidelarge" />
          </div>
        </div>

        <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
          {texts.calender}
        </h1>
        <hr />
        <MonthView
          options={options}
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
          getEventsForDay={getEventsForDay}
          selectedDate={selectedDate}
          paramStatusFilter={paramStatusFilter}
          setParamStatusFilter={setParamStatusFilter}
          setSelectedDate={setSelectedDate}
          loading={loading}
          interviewList={interviewList}
          initialMonth={moment()}
          initialDate={null}
        />
      </div>
    </div>
  );
};

export default Calender;
