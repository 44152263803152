import React from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";

const SubmitModal = ({ submitModal, setSubmitModal, textsExtra, BigLogo }) => {
  return (
    <Modal
      open={submitModal}
      onCancel={() => setSubmitModal(false)}
      style={{ borderRadius: 100 }}
      width={500}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[27rem]">
        <p className="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          {textsExtra.thankYouTexts}
        </p>

        <img
          src={BigLogo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8 md:py-10 p-10 mb-4"
        />
        <button
          className="p-3 bg-[#404041] text-white font-bold rounded-full shadow-sm text-xl hover:scale-95 hover:shadow-lg w-10/12 lg:mt-4 mt-0"
          onClick={() => setSubmitModal(false)}
        >
          {textsExtra.continue}
        </button>
      </div>
    </Modal>
  );
};

export default SubmitModal;
