import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginLoader = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 100);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center h-screen"></div>
  );
};

export default LoginLoader;
