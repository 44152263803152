import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import commentIcon from "../../assets/comment.svg";
import { BsThreeDots } from "react-icons/bs";
import CancelReshduleDropdown from "./CancelReshduleDropdown";
import InterviewScheduleModal from "../messagePage/interview/InterviewScheduleModal";

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  const formattedString = string.replace(/-/g, " ");
  return formattedString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const InterviewListItem = ({ interview }) => {
  const [isInterviewScheduleModalVisible, setIsInterviewScheduleModalVisible] =
    useState(false);
  const user = interview.channel_users[1].user;

  const timeValues = interview.interview_time_values
    ? interview.interview_time_values.split(",")
    : null;
  const startTime = timeValues ? timeValues[0] : null;
  const endTime = timeValues ? timeValues[1] : null;
  const openInterviewScheduleModal = () => {
    const interviewDetails = {
      date: interview.interview_date, // Assuming interview_time contains the date
      time: interview.interview_time_values, // Assuming interview_time_values contains the time
      type: interview.interview_type, // Store the interview type
    };
    localStorage.setItem("interviewDetails", JSON.stringify(interviewDetails));
    setIsInterviewScheduleModalVisible(true);
  };

  const closeInterviewScheduleModal = () => {
    localStorage.removeItem("status");
    setIsInterviewScheduleModalVisible(false);
  };
  return (
    <>
      {interview.interview_confirm !== 2 && (
        <li
          className={`p-4 rounded-lg shadow-sm ${
            interview.interview_confirm === 2 ? " bg-gray-100" : "bg-white"
          }`}
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0 ml-5">
              {user.profile_image !== "default.png" ? (
                <Link to={`/user/${user.id}`} className="z-40">
                  <img
                    className={`w-10 h-10 rounded-full object-cover ${
                      interview.interview_confirm === 2 ? "grayscale" : ""
                    } ${
                      user.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${user.profile_image}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-gray-300 ${
                    user.type === "employer"
                      ? "ring-2 ring-[#E7831D] rounded-full"
                      : "ring-2 ring-gray-700 rounded-full"
                  }`}
                />
              )}
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-md font-semibold text-gray-900">
                    {user.first_name} {user.last_name}
                  </h3>
                  <p className="text-xs text-gray-600">
                    @{user.username} -{" "}
                    <span
                      className={`${
                        user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(user.type)}
                      <IoIosCheckmarkCircle className="inline ml-1" />
                    </span>
                  </p>
                </div>
                <span className="text-xs text-gray-500"></span>
              </div>

              <div className="mt-3 flex flex-wrap gap-2 justify-between">
                <div className="text-sm text-gray-600">
                  <span className="font-semibold">
                    {interview.interview_type}
                  </span>{" "}
                  {interview.interview_confirm === 0 && timeValues ? (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  ) : (
                    <>at {interview.interview_time}</>
                  )}
                  {interview.interview_confirm === 3 && timeValues && (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  )}
                  {interview.interview_confirm === 2 && timeValues && (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  )}
                </div>
                {interview.interview_confirm === 1 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                    />
                  </div>
                )}
                {interview.interview_confirm === 3 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                    />
                  </div>
                )}

                {interview.interview_confirm === 0 && (
                  <Link
                    to={`/dm/${interview.channel_id}/${user.id}`}
                    className="text-xs text-white bg-[#E7831D] rounded-full px-2 py-1 cursor-pointer"
                  >
                    Pending
                  </Link>
                )}
                {interview.interview_confirm === 2 && (
                  <Link
                    to={`/dm/${interview.channel_id}/${user.id}`}
                    className="text-xs text-white bg-[#e71d1d]/70 rounded-full px-2 py-1 cursor-pointer"
                  >
                    Canceled
                  </Link>
                )}
              </div>
            </div>
          </div>
        </li>
      )}
      {interview.interview_confirm === 2 && (
        <li className={`p-4 rounded-lg shadow-sm `}>
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0 ml-5">
              {user.profile_image !== "default.png" ? (
                <Link to={`/user/${user.id}`} className="z-40">
                  <img
                    className={`w-10 h-10 rounded-full object-cover ${
                      interview.interview_confirm === 2 ? "grayscale" : ""
                    } ${
                      user.type === "employer"
                        ? "ring-2 ring-[#E7831D]"
                        : "ring-2 ring-gray-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${user.profile_image}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-gray-300 ${
                    user.type === "employer"
                      ? "ring-2 ring-[#E7831D] rounded-full"
                      : "ring-2 ring-gray-700 rounded-full"
                  }`}
                />
              )}
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-md font-semibold text-gray-900">
                    {user.first_name} {user.last_name}
                  </h3>
                  <p className="text-xs text-gray-600">
                    @{user.username} -{" "}
                    <span
                      className={`${
                        user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(user.type)}
                      <IoIosCheckmarkCircle className="inline ml-1" />
                    </span>
                  </p>
                </div>
                <span className="text-xs text-gray-500"></span>
              </div>

              <div className="mt-3 flex flex-wrap gap-2 justify-between">
                <div className="text-sm text-gray-600">
                  <span className="font-semibold">
                    {interview.interview_type}
                  </span>{" "}
                  {interview.interview_confirm === 0 && timeValues ? (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  ) : (
                    <>at {interview.interview_time}</>
                  )}
                  {interview.interview_confirm === 3 && timeValues && (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  )}
                  {interview.interview_confirm === 2 && timeValues && (
                    <>
                      {startTime}
                      {endTime && ` or ${endTime}`}
                    </>
                  )}
                </div>
                {interview.interview_confirm === 1 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                    />
                  </div>
                )}
                {interview.interview_confirm === 3 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                    />
                  </div>
                )}

                {interview.interview_confirm === 0 && (
                  <Link
                    to={`/dm/${interview.channel_id}/${user.id}`}
                    className="text-xs text-white bg-[#E7831D] rounded-full px-2 py-1 cursor-pointer"
                  >
                    Pending
                  </Link>
                )}
                {interview.interview_confirm === 2 && (
                  <Link
                    to={`/dm/${interview.channel_id}/${user.id}`}
                    className="text-xs text-white bg-[#e71d1d]/70 rounded-full px-2 py-1 cursor-pointer"
                  >
                    Canceled
                  </Link>
                )}
              </div>
            </div>
          </div>
        </li>
      )}
      <InterviewScheduleModal
        isVisible={isInterviewScheduleModalVisible}
        onClose={closeInterviewScheduleModal}
      />
    </>
  );
};

export default InterviewListItem;
