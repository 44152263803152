import React from "react";
import SettingsListOne from "./SettingsListOne";
import SettingsListTwo from "./SettingsListTwo";
import SettingsListThree from "./SettingsListThree";
import SocialsTooltip from "./SocialsTooltip";
import { useSelector } from "react-redux";
import translations from "../../languages";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
const SettingsMain = ({ setSidebarOpen }) => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  return (
    <div className="scrollbar-hide w-auto flex flex-col paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>

      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
        {texts.settings}
      </h1>
      <hr />
      <SettingsListOne />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListTwo />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListThree />
      <div className="w-full h-2 bg-gray-100"></div>
      {/* <SocialsTooltip /> */}
      {/* <footer className="bg-white border-t border-gray-200  h-1 py-5 ">
        <div className="text-center text-xs text-gray-500 items-center">
          <a
            href="https://www.bocaenboca.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-700 mt-3 "
          >
            Boca En Boca is a trademark of The Peddler Group, Inc. Copyright
            2022.
          </a>
        </div>
      </footer> */}
    </div>
  );
};

export default SettingsMain;
