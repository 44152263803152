import React, { useState, useEffect } from "react";
import { Modal, Input, Button, message, Space, Spin } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
const MyPasswordModal = ({ closeModal }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userDetails = response.data.content;
        setEmail(userDetails.email);
        setPhone(userDetails.phone);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleSave = async () => {
    if (newPassword !== confirmNewPassword) {
      message.error("New password and confirm password do not match");
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/forget-password/reset`,
        {
          identity: email,
          identity_type: "email",
          otp: "1234",
          password: newPassword,
          confirm_password: confirmNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast("Password changed successfully", {
        style: {
          backgroundColor: "#333", // Gray background
          color: "#fff", // White text
        },
      });
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error("Failed to change password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-gray-700 font-bold">Change Password</h1>
        <Input.Password
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Current Password"
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone className="bg-gray-500" />
            ) : (
              <EyeInvisibleOutlined />
            )
          }
          onPressEnter={handleSave}
          visibilityToggle
          className="rounded-full p-3 outline-none "
        />
        <Input.Password
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onPressEnter={handleSave}
          visibilityToggle
          className="rounded-full p-3 outline-none "
        />
        <Input.Password
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          placeholder="Confirm New Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onPressEnter={handleSave}
          visibilityToggle
          className="rounded-full p-3 outline-none "
        />

        <span
          className="text-sm text-orange-500 text-center cursor-pointer"
          onClick={() => navigate("/email-fp")}
        >
          Forget Password
        </span>

        <Button
          onClick={handleSave}
          className="w-full  text-white font-bold bg-[#404041] hover:scale-95 rounded-full h-12"
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </Button>
      </div>
    </Modal>
  );
};

export default MyPasswordModal;
