import React, { useState } from "react";

const Tags = ({ options, activeTags, onTagClick }) => {
  const [hoveredTag, setHoveredTag] = useState(null);

  const handleMouseEnter = (key) => {
    setHoveredTag(key);
  };

  const handleMouseLeave = () => {
    setHoveredTag(null);
  };

  return (
    <div className="mt-3 flex gap-3 flex-wrap text-center justify-center w-full">
      {Object.keys(options).map((key) => (
        <div
          key={key}
          className={`flex py-1 rounded-full border-2  cursor-pointer text-sm justify-center items-center w-[9.85rem] h-11 overflow-hidden ${
            activeTags.includes(key)
              ? "bg-[#E7831D]  text-xs text-white border-none "
              : "bg-gradient-to-r from-neutral-50 to-white text-gray-600 border-gray-400  text-xs"
          }`}
          onClick={() => onTagClick(key)}
          onMouseEnter={() => handleMouseEnter(key)}
          onMouseLeave={handleMouseLeave}
          style={{
            maxWidth: "340px", // Adjust this width as needed
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%", // Ensure text doesn't overflow the tag container
            }}
          >
            {options[key]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Tags;
