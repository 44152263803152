import React, { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Downicon from "../../assets/downarrow.svg";
import translations from "../../languages";
import { useSelector } from "react-redux";

const InterviewFilters = ({ paramStatusFilter, setParamStatusFilter }) => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const language = translations[selectedLanguage]
    ? selectedLanguage
    : "English";

  const options = [
    translations[language].buttons.all,
    translations[language].buttons.scheduled,
    translations[language].buttons.pending,
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  console.log(selectedOption, "selectedOption");

  useEffect(() => {
    if (paramStatusFilter === "1") {
      setSelectedOption(translations[language].buttons.scheduled);
    } else if (paramStatusFilter === "0") {
      setSelectedOption(translations[language].buttons.pending);
    } else {
      setSelectedOption(options[0]);
    }
  }, [paramStatusFilter]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === translations[language].buttons.scheduled) {
      setParamStatusFilter("1");
    } else if (option === translations[language].buttons.pending) {
      setParamStatusFilter("0");
    } else {
      setParamStatusFilter("");
    }
  };

  return (
    <div className="ml-8 mt-3 relative z-50">
      <Menu as="div" className="inline-block text-left ml-auto">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-full border-2 px-6 py-2 text-gray-700 text-sm">
            {selectedOption}
            <img
              src={Downicon}
              className="-mr-1 ml-2 h-4 w-4"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1">
              {options.map((option, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-gray-700 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => handleOptionChange(option)}
                    >
                      {option}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default InterviewFilters;
