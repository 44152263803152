import { Fragment } from "react";
import moment from "moment";
import { Menu, Transition } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MonthDropdown({ currentMonth, setCurrentMonth }) {
  const months = moment.months();

  const handleSelectMonth = (month) => {
    setCurrentMonth(currentMonth.clone().month(month));
  };

  return (
    <Menu as="div" className="relative inline-block w-3/4">
      <Menu.Button className="inline-flex justify-between w-full px-4 py-2 bg-white text-sm font-medium text-gray-700  hover:bg-gray-50 border-2 rounded-full">
        {months[currentMonth.month()]}
        <FaChevronDown className="ml-2 -mr-1 h-5 w-5 text-gray-400" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {months.map((month, index) => (
            <Menu.Item key={month}>
              {({ active }) => (
                <button
                  onClick={() => handleSelectMonth(index)}
                  className={classNames(
                    active
                      ? "bg-[#424242] text-white rounded-full"
                      : "text-gray-900",
                    "block px-4 py-2 text-sm w-full text-left"
                  )}
                >
                  {month}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
