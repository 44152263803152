// src/api.js
import axios from "axios";

const baseURL = "https://bocaenboca.app/backend";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const registerUser = async (userData) => {
  try {
    const response = await axiosInstance.post(
      "/api/v1/customer/auth/registration",
      userData
    );
    return response.data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};
export const loginUser = async (userData) => {
  try {
    const response = await axiosInstance.post(
      "api/v1/customer/auth/login",
      userData
    );
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const fetchPosts = async () => {
  try {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const requestBody = {
      limit: "100",
      offset: "1",
      string: "",
    };

    const response = await axiosInstance.get("/api/v1/user/post/list", {
      headers,
      params: requestBody,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw error;
  }
};
export const fetchUserDetails = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get("/api/v1/user/details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const fetchPagesSetup = async (pageName) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get(
      `/api/v1/admin/business-settings/get-pages-setup?page_name=${pageName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching page setup:", error);
    throw error;
  }
};
