import React, { useState, Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { Toaster, toast } from "sonner";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin, Modal } from "antd";
import { IoClose } from "react-icons/io5";

const people = [
  { id: 1, name: "Job Seeker" },
  { id: 2, name: "Employer" },
];

const RoleModal = ({ closeModal, data }) => {
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSave = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const role = selected.name === "Job Seeker" ? "job-seeker" : "employer";
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/user/update/${userId}`,
        { type: role },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast(`Role ${response.data.message}`, {
        style: {
          backgroundColor: "#333", // Gray background
          color: "#fff", // White text
        },
      });
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      toast.error("There was an error updating the role!", error);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
        <h1 className="text-lg text-gray-700 font-bold">Role</h1>

        <div className="w-full">
          <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
              <div className="relative w-full cursor-default p-2 border-2 bg-white text-left rounded-full sm:text-sm">
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
                  displayValue={(person) =>
                    person ? person.name : "Choose a role"
                  }
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <FiChevronDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="border-gray-300 border-2 rounded-2xl overflow-y-auto mt-5">
                  {filteredPeople.length === 0 && query !== "" ? (
                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `text-gray-900 px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                            active ? "bg-gray-300 text-black" : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>

        <button
          className="w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full"
          onClick={handleSave}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </Modal>
  );
};

export default RoleModal;
