import React from "react";

const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-50 text-sm">
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Legal</h1>
        <p className="mb-4">
          Boca En Boca™ is a trademark owned by The Peddler Group, Inc., a
          corporation formed in Texas, established on December 18, 2018.
        </p>
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">Effective as of September 15, 2024</p>

        <ul className="list-decimal ml-5 mb-4">
          <li>About This Policy</li>
          <li>Your Personal Data Rights and Controls</li>
          <li>Personal Data we Collect</li>
          <li>Purposes For Processing Your Personal Data</li>
          <li>Disclosure Of Your Personal Data</li>
          <li>Data Retention</li>
          <li>Data Security</li>
          <li>Children's Privacy</li>
          <li>Changes To This Policy</li>
          <li>Dispute Resolution and Arbitration Agreement</li>
          <li>Contact Information</li>
        </ul>

        <h3 className="text-lg font-bold mb-2">1. About this Policy</h3>
        <p className="mb-4">
          This Privacy Policy ('Policy') outlines how The Peddler Group, Inc.
          processes personal data in connection with the Boca En Boca™ mobile
          application. It describes our practices regarding the collection, use,
          and handling of your information.
        </p>
        <p className="mb-4">
          This Policy applies to:
          <ul className="list-disc ml-5 mt-2">
            <li>Your use of all Boca En Boca™ services, including:</li>
            <li>Accessing Boca En Boca™ on any device</li>
            <li>Personalization of your user experience</li>
            <li>Infrastructure supporting our services</li>
            <li>
              Integration of your Boca En Boca™ account with third-party
              applications
            </li>
            <li>Both free and paid subscription tiers</li>
            <li>
              Other Boca En Boca™ services that link to this Policy, such as our
              websites and customer support platforms.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          You agree to the Privacy Policy set here by use of the Boca En Boca
          website and or mobile app with or without a registered account.
        </p>
        <p className="mb-4">
          Collectively, these will be referred to as the "Boca En Boca™
          Service."
        </p>
        <p className="mb-4">
          This Policy does not cover:
          <ul className="list-disc ml-5 mt-2">
            <li>
              The Boca En Boca™ Terms and Conditions, which is a separate legal
              agreement between you and The Peddler Group, Inc. governing your
              use of the Boca En Boca™ Service.
            </li>
            <li>
              Your use of other Boca En Boca™ services that are subject to their
              own privacy policies.
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">
          2. Your Personal Data Rights and Controls
        </h3>
        <p className="mb-4">
          Applicable privacy laws grant you specific rights regarding your
          personal data. As a user of the Boca En Boca™ Service, you have the
          right to:
          <ul className="list-disc ml-5 mt-2">
            <li>
              Be informed about the personal data we process concerning you
            </li>
            <li>Access the personal data we hold about you</li>
            <li>Rectify any inaccurate personal data</li>
            <li>
              Request the deletion of your personal data under certain
              circumstances
            </li>
            <li>
              Restrict or object to specific processing of your personal data
            </li>
            <li>Request the portability of your data</li>
            <li>
              Withdraw your consent at any time, where processing is based on
              consent
            </li>
          </ul>
        </p>
        <p className="mb-4">
          To exercise these rights or for any related inquiries, please contact
          The Peddler Group, Inc. using the contact information provided at the
          conclusion of this Policy.
        </p>

        <h3 className="text-lg font-bold mb-2">3. Personal Data We Collect</h3>
        <p className="mb-4">
          The Peddler Group, Inc. collects the following categories of personal
          data through the Boca En Boca™ Service:
        </p>
        <p className="mb-4">
          User Data - Information required to create your account, including:
          <ul className="list-disc ml-5 mt-2">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Date of birth</li>
          </ul>
        </p>
        <p className="mb-4">
          Usage Data - Information about your interaction with the Boca En Boca™
          Service, including:
          <ul className="list-disc ml-5 mt-2">
            <li>Job search history</li>
            <li>Interactions with employers and/or job seekers</li>
            <li>Device information</li>
          </ul>
        </p>
        <p className="mb-4">
          Location Data - General location information (e.g., country or city)
          derived from:
          <ul className="list-disc ml-5 mt-2">
            <li>IP address</li>
            <li>Other information you provide</li>
          </ul>
        </p>
        <p className="mb-4">
          Communication Data - Messages exchanged within the Boca En Boca
          ™Service:
          <ul className="list-disc ml-5 mt-2">
            <li>Between users</li>
            <li>With our customer support team</li>
          </ul>
        </p>
        <p className="mb-4">
          Payment Data - Information necessary to process transactions for paid
          services
        </p>

        <h3 className="text-lg font-bold mb-2">
          4. Purposes for Processing Your Personal Data
        </h3>
        <p className="mb-4">
          The Peddler Group, Inc. processes your personal data collected through
          the Boca En Boca™ Service for the following purposes:
          <ul className="list-disc ml-5 mt-2">
            <li>
              Service Provision and Personalization - To deliver and tailor the
              Boca En Boca™ Service to your preferences
            </li>
            <li>
              Service Improvement and Innovation - To enhance existing services
              and develop new features based on user interactions and feedback
            </li>
            <li>
              Account Management and Communication - To facilitate
              account-related notifications and provide information about our
              services
            </li>
            <li>
              Platform Security and Functionality - To maintain the integrity,
              security, and proper operation of the Boca En Boca™ platform
            </li>
            <li>
              Legal Compliance - To adhere to applicable laws, regulations, and
              legal obligations
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">
          5. Disclosure of Your Personal Data
        </h3>
        <p className="mb-4">
          The Peddler Group Inc. may share personal data collected through the
          Boca En Boca Service with the following categories of recipients:
          <ul className="list-disc mt-2 ">
            <p>
              Platform Users - Employers and job seekers using the Boca En Boca™
              Service, in the context of:
              <p className="mt-2">Direct interactions</p>
              <p> Search functionality within the platform</p>
            </p>
            <li className="ml-4 mt-1">
              Service Providers - Third-party entities that assist in the
              operation and maintenance of our business
            </li>
            <li className="ml-4">
              Affiliated Companies - Other entities within The Peddler Group,
              Inc. corporate structure
            </li>
            <li className="ml-4">
              Legal and Regulatory Authorities - Law enforcement agencies or
              other governmental bodies, as required by applicable laws or
              regulations
            </li>
            <li className="ml-4">
              Business Transferees - Potential buyers or merger partners in the
              event of a sale, merger, or acquisition involving The Peddler
              Group, Inc. or the Boca En Boca™ Service
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">6. Data Retention</h3>
        <p className="mb-4">
          The Peddler Group Inc. retains your personal data only for as long as
          necessary to:
          <ul className="list-disc ml-5 mt-2">
            <li>Provide the Boca En Boca™ Service</li>
            <li>Fulfill legitimate business purposes</li>
            <li>Comply with legal obligations</li>
          </ul>
          Retention periods vary based on data type and applicable legal
          requirements.
        </p>

        <h3 className="text-lg font-bold mb-2">7. Data Security</h3>
        <p className="mb-4">
          To protect your personal data, The Peddler Group, Inc. implements
          appropriate technical and organizational measures against unauthorized
          access, alteration, disclosure, or destruction. However, please note
          that no Internet-based or electronic storage method is 100% secure.
        </p>

        <h3 className="text-lg font-bold mb-2">8. Children's Privacy</h3>
        <p className="mb-4">
          The Boca En Boca™ Service is not intended for individuals under 18
          years of age. The Peddler Group, Inc. does not knowingly collect
          personal data from children. If we become aware of any collected data
          from a child under 18 years of age, we will take prompt steps to
          delete such information.
        </p>

        <h3 className="text-lg font-bold mb-2">9. Changes to this Policy</h3>
        <p className="mb-4">
          The Peddler Group, Inc. may update this Privacy Policy periodically
          with or without notification to the users.
        </p>

        <h3 className="text-lg font-bold mb-2">
          10. Dispute Resolution and Arbitration Agreement
        </h3>
        <p className="mb-4">
          By agreeing to this Privacy Policy, you acknowledge and waive any
          rights to pursue legal action against The Peddler Group, Inc. related
          to or arising from the Boca En Boca™ Service or this Privacy Policy.
          Furthermore, you agree that any dispute resolution will be conducted
          on an individual basis, and you waive the right to participate in any
          class action.
        </p>
        <p className="mb-4">
          The Peddler Group, Inc. and Boca En Boca™ are not responsible for any
          legal fees incurred by you. In the event you initiate legal action
          against The Peddler Group, Inc. or Boca En Boca™, you will be solely
          responsible for covering all legal costs associated with such action
          for all parties involved.
        </p>
        <p className="mb-4">
          By using Boca En Boca™, whether with or without a registered user
          account, you are entering into a Hold Harmless Agreement with The
          Peddler Group, Inc. and Boca En Boca™, absolving both of any liability
          for claims, damages, or losses arising from your use of the service.
        </p>

        <h3 className="text-lg font-bold mb-2">11. Contact Information</h3>
        <p className="mb-4">
          For questions regarding this Privacy Policy, please contact our Data
          Protection Officer
          <a
            href="mailto:admin@bocaenboca.com"
            className="text-orange-500 ml-1"
          >
            admin@bocaenboca.com
          </a>
          .
        </p>
        <p className="mb-4">
          The Peddler Group, Inc. is the data controller for personal data
          processed under this Policy.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
