import React from "react";

const WelcomeBack = ({ text }) => {
  return (
    <div className="flex space-x-2 mt-2 justify-center items-center">
      <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
      <div className="text-[#818181] text-sm">{text}</div>
      <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
    </div>
  );
};

export default WelcomeBack;
