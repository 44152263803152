import React from "react";
import logo from "../../assets/mainlogonew.png";

const LeftAlignedAuthFormHeading = ({ text, welcome }) => {
  return (
    <div className="text-center mb-1">
      <h1 className="text-[#818181] text-2xl ml-2 mt-1 mb-1">{welcome}</h1>
      <div className="flex items-center justify-center mt-3">
        <img src={logo} alt="logo" className="w-full h-auto px-16  mb-3 " />
      </div>
      <div className="flex space-x-2 mt-2 mb-2 justify-center items-center w-full ">
        <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
        <div className="text-[#818181] text-sm ">{text}</div>
        <div className="h-[0.05rem] w-1/3 bg-[#dbdbdb]"></div>
      </div>
    </div>
  );
};

export default LeftAlignedAuthFormHeading;
